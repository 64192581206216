
#searchBox {
  font-size: 2em;
  border-radius: 15px;
  padding: 10px;
}

#searchBox:focus {
  border-color: #FFE87C;
  outline: 0;
  box-shadow: 0 0 10px #FFE87C;
}
