
.results {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  background-color: white;
  text-align: left;
  border-radius: 15px;
  padding: 1em;
}

.results h2 {
  margin: 0;
}

.results li {
  margin-bottom: 1em;
}

.score {
  display: block;
  color: gray;
}

.matchingWord {
  background-color: #FFE87C;
}
