
@media (prefers-reduced-motion: no-preference) {
  .waiting {
    align-items: center;
    justify-items: center;
    animation: Search-Pony 0.25s linear infinite;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.waiting {
  font-size: 2em;
  color: white;
  text-decoration: blink;

}

@keyframes Search-Pony {
  0% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(1deg);
  }
}

